// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-page-jsx": () => import("/opt/build/repo/src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-post-jsx": () => import("/opt/build/repo/src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-pages-contact-jsx": () => import("/opt/build/repo/src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-noutati-jsx": () => import("/opt/build/repo/src/pages/noutati.jsx" /* webpackChunkName: "component---src-pages-noutati-jsx" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/opt/build/repo/.cache/data.json")

